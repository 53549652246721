.playerControlWrapper{
  width: 640px;
  max-width: 100%;
  margin: 0 auto;
  background-color: black;
}

.wrapper{
  position: relative;
  height: 360px;
  margin: 0;
  max-width: 100%;
}

.container{
  position: relative;
  height: 100%;
  width: 100%;
}

.reactPlayer{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.controlsOverlay{
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0px;
  padding: 5px;
  z-index: 10;
  background-color: #000000bb;
  color: #ffbf50;
  opacity: 0;
}

.controlsOverlay:hover{
  opacity: 1;
}

.controlsBeneath{
  background-color: black;
  color: #ffbf50;
  padding: 5px;
  width: 640px;
  max-width: 100%;
  margin: 0 auto;
}

.topRow{
  display: flex;
  justify-content: center;
  height: 40px;
}

.playPauseSkip{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 40%;
  max-width: 200px;
}

  .playPauseSkip i{
    font-size: 2em;
    cursor: pointer;
  }

.secondRow{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.volumeContainer,.speedContainer{
  display: flex;
  flex-direction: column;
  width: 45%;
  min-width: 250px;
  margin: 0 auto;
}

  .volumeContainer div, .speedContainer div{
    display: flex;
    justify-content: center;
  }

  .volumeContainer div i, .speedContainer div i{
    font-size: 12pt;
    cursor: pointer;
    margin: 0 10px;
  }



.thirdRow{
  display: flex;
  justify-content: space-between;
}

.fullscreenButton{
  font-size: 18pt;
  cursor: pointer;
}

  

.playProgressBarContainer{
  width: 90%;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.playProgressBar{
  width: 100%;
  margin: 0 10px;
  color: yellow;
}

.instructionModal{
  z-index: 20;
}